<template>
  <div class="program-add-measure">
    <ScheduleMeasure
      ref="scheduleMeasure"
      context="program"
      :loading="isLoading"
      :title="program.name"
      :show-days="true"
      :show-add-another="true"
      :available-measures="schedulingOptions.available_measures"
      :frequency-options="schedulingOptions.frequency_options"
      :measures-assigned="schedulingOptions.measures_assigned"
      :show-measures-assigned="true"
      :save-btn-text="saveText"
      @schedule="schedule"
      @finish="finish"
      @delete="deleted"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Helpers } from '@/mixins/Helpers'
import ScheduleMeasure from '@/components/common/schedule/ScheduleMeasure'
import { GET_PROGRAMS } from '@/store/modules/programs/constants.js'

export default {
  name: 'ClinicianProgramAddMeasure',
  components: {
    ScheduleMeasure
  },
  mixins: [Helpers],
  data () {
    return {
      program_id: null,
      isLoading: false
    }
  },
  computed: {
    ...mapGetters({
      program: 'getSingleProgram',
      schedulingOptions: 'getSingleProgramMeasures'
    }),
    saveText () {
      return this.$t('done')
    }
  },
  created () {
    this.isLoading = true
    this.program_id = this.$route.params.programId
    this.$store.dispatch('GET_PROGRAM', this.program_id).then(() => {
      this.$store.dispatch('GET_PROGRAM_MEASURES', this.program_id).finally(() => {
        this.isLoading = false
      })
    })
  },
  methods: {
    schedule (options) {
      const schedule = options.scheduleData
      const loader = this.$loading.show()

      schedule.program_id = this.program_id

      this.$store.dispatch('SCHEDULE_PROGRAM_MEASURE', schedule)
        .then(() => {
          if (options && options.addAnother) {
            this.$refs.scheduleMeasure.resetForm()
            this.$store.dispatch('GET_PROGRAM_MEASURES', this.program_id).finally(() => {})
          } else {
            this.$toast.success({ message: this.$t('programSumbit', { action: this.$route.query.editing === true ? 'updated' : 'added' }) })
            this.$store.dispatch(GET_PROGRAMS)
            this.$router.push({ name: 'SettingsPrograms' })
          }
        })
        .catch((e) => {
          this.$handleApiError(e)
        })
        .finally(() => {
          loader.hide()
        })
    },
    deleted () {
      this.isLoading = true
      this.$store.dispatch('GET_PROGRAM_MEASURES', this.program_id).finally(() => {
        this.isLoading = false
      })
    },
    finish () {
      this.isLoading = true
      this.$store.dispatch(GET_PROGRAMS).then(() => {
        this.isLoading = false
      })
      this.$router.push({ name: 'SettingsPrograms' })
    }
  }
}
</script>
<style lang="scss" scoped>
.program-add-measure {
  height: 100%;
}
</style>
